//Navbar_mod.vue
<template>
  <nav class="navbar">
    <div class="container">
      <!-- Align the speech bubble with the character's mouth -->
      <div class="character-container">
          <img id="character" src="../../images/puck.png" alt="Logo" class="navbar-logo" @click="backToApps"/>
          <div id="speech-bubble" class="speech">
            {{store.pucksMessage}}
          </div>
            </div>
      <div class="controls-container" v-if="store.isLoggedIn">

        <router-link
            to="/home"
            class="btn btn-warning logout-button"
        >
          <i class="bi bi-box-arrow-right"></i> Retour aux applications
        </router-link>
        <button @click="logout" class="btn btn-danger logout-button">
          <i class="bi bi-box-arrow-right"></i> Déconnexion
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
import { store } from '@/store';

export default {
  setup() {
    return { store };
  },
  props: {
    userName: String,
  },
  watch: {
    'store.pucksMessage'(newValue) {
      // Rafraîchissement de Navbar chaque fois que pucksMessage change
      console.log("pucksMessage a changé:", newValue);
      // Vous pouvez également ajouter des animations ou d'autres effets ici
    }
  },
  methods: {
    logout() {
      console.log("logout dans navbar");
      localStorage.removeItem("appStore");
      this.$emit("logout");
    },
    backToApps() {
      console.log("back to home page in navbar");
store.showApp = ""    }
  },
};
</script>

<style scoped>
@font-face {
  font-family: 'Obelix Pro';
  src: url('../../fonts/ComicMarkerDeluxe-v4Z4.ttf') format('truetype');
}

.navbar {
  background-color: #0d1117;
  border-bottom: 2px solid #58a6ff;
  padding: 1rem 0;
}

.navbar-brand {
  display: flex;
  align-items: center;
  font-family: "Orbitron", sans-serif;
  color: #58a6ff;
  font-size: 1.5rem;
}

.character-container {
  position: relative;
  display: flex;
  align-items: flex-start; /* Aligne la bulle et le personnage en haut */
  width: 50%; /* Permet au conteneur de prendre toute la largeur disponible */
}

.navbar-logo {
  max-height: 120px;
  width: auto;
  margin-left: 0.5rem;
  cursor: pointer;
}
.speech {
  background-color: #ede1d9;
  border: solid 2px #000;
  border-radius: 12px;
  display: inline-block;
  margin: 0.5em 0; /* Réduire le margin latéral pour permettre plus d'espace */
  padding: 1em; /* Augmenter le padding pour un contenu plus spacieux */
  position: absolute;
  top: -30px; /* Ajuster selon la position du personnage */
  left: 150px; /* Ajuster selon la position du personnage */
  width: auto; /* Permet d'adapter la largeur */
  max-width: 100%; /* Limite la largeur maximale */
  font-family: 'Obelix Pro', cursive, sans-serif;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.5;
  color: #333;
  text-align: left;
}

.speech:before {
  border: solid 12px transparent;
  border-left: solid 12px #000;
  border-top: solid 12px #000;
  bottom: -24px;
  content: "";
  height: 0;
  left: 24px; /* Ajuster selon la position de la bulle */
  position: absolute;
  transform: skew(-15deg);
  width: 0;
}

.speech:after {
  border: solid 10px transparent;
  border-left: solid 10px #ede1d9;
  border-top: solid 10px #ede1d9;
  bottom: -19px;
  content: "";
  height: 0;
  left: 27px; /* Ajuster selon la position de la bulle */
  position: absolute;
  transform: skew(-15deg);
  width: 0;
}

.logout-button {
  margin-left: auto;
  color: white;
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .container > * {
  display: flex;
  align-items: center;
}

.controls-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
</style>
