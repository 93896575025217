//GpxAnalyzer.vue
<template>
  <div class="multi-tools">
    <div>
      <div class="image-button-container">
        <div
            v-if="showApp !== 'file-upload-gpx'"
            class="image-button"
            @click="goToFileUploadGpx"
            :style="{ backgroundImage: 'url(' + gpxAnalyzerImg + ')' }"
        >
          <span class="button-text">I work out !<br />🦸💪🔥🏋️‍♂️ 🏃‍♂️💥🎶</span>
        </div>
        <div class="sub-text" @click="goToFileUploadGpx">
          Demande de rapport d'analyses <br />
          de trajets GPX
        </div>
        <FileUploaderGen
            v-if="store.isLoggedIn && showApp === 'file-upload-gpx'"
            :route="'/queue_job?job_type=gpx'"
            :fileName="''"
            @upload-complete="resetView"
        />
      </div>
    </div>
    <!-- Pop-up stylisée -->
    <div v-if="showPopup" class="custom-popup">
      <div class="popup-content">
        <p>
          Traitement 📊 du trajet en file d'attente, l'adresse
          <i>{{ store.userInfo.userEmail }}</i> <br />
          va se faire statisfacter ✅ 📨
        </p>
        <p class="highlighted-text">
          <strong>Je profite de cet espace pour vous partager un lien :</strong> <br />
          <a href="https://maitron.fr/spip.php?article178905" target="_blank" class="marcel-link">
            <em>L'histoire de Marcel Bayon</em>
          </a>, résistant fusillé, <br />
          dont je croise la plaque commémorative lors de mes parcours de vélo 🚴‍♂️.
        </p>
        <button @click="closePopup" class="btn-close-marcel">
          OK
        </button>

      </div>
    </div>
  </div>
</template>

<script>
import FileUploaderGen from "./FileUploaderGen.vue";
import {store} from "@/store";
import gpxAnalyzerImg from "../../images/gpx_stat.webp";

export default {
  name: "GpxAnalyzer",
  computed: {
    userRole() {
      return store.userInfo.userRole;
    }
  },
  setup() {
    return {store};
  },
  components: {
    FileUploaderGen,
  },
  data() {
    return {
      gpxAnalyzerImg,
      showApp: "",
      showPopup: false,
    };
  },
  methods: {
    goToFileUploadGpx() {
      this.showApp = "file-upload-gpx";
    },
    resetView() {
      if (this.showApp === "file-upload-gpx") {
        this.showPopup = true;
      }
      this.showApp = "";
    },
    closePopup() {
      this.showPopup = false;
    }
  },
  mounted() {
    console.log("Mounted GpxAnalyzer. UserRole:", store.userInfo.userRole);
    store.updatePucksSpeech("J'espere que tout ca ne s'est pas fait a l'insu de votre plein gre!🚴‍♂️");
  }
};
</script>

<style scoped>
.custom-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: #1a1a1d;
  color: #f2f2f2;
  padding: 20px 30px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.highlighted-text {
  margin-top: 15px;
  font-size: 1.1rem;
  color: #f8c471;
}

.marcel-link {
  color: #ffd700;
  text-decoration: underline;
  font-weight: bold;
}

.marcel-link:hover {
  color: #ffa500;
}

.btn-close-marcel {
  background-color: #ffd700; /* Jaune doré */
  border: none;
  padding: 10px 20px;
  border-radius: 30px; /* Coins arrondis */
  cursor: pointer;
  color: #333; /* Couleur du texte */
  font-size: 1rem;
  font-weight: bold;
  text-shadow: 0 1px 2px rgba(255, 255, 255, 0.3);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), inset 0 -2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.btn-close-marcel:hover {
  background-color: #ffc107; /* Un jaune plus clair */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3), inset 0 -3px 6px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px); /* Légère élévation */
}

.btn-close-marcel:active {
  background-color: #e6b800; /* Jaune légèrement plus sombre */
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.3);
  transform: translateY(0); /* Retirer l'élévation */
}

</style>
