//Login_mod.vue

<template>
  <div class="login-container d-flex flex-column justify-content-center align-items-center">
    <h2 class="title">Se connecter</h2>
    <p class="subtitle">pour continuer vers votre application.</p>

    <form v-if="!isAuthenticated" @submit.prevent="login" class="login-form">
      <div class="form-group-login">
        <label for="username" class="form-label">E-mail ou nom d'utilisateur</label>
        <input
            type="text"
            id="username"
            class="form-control"
            v-model.trim="username"
            required
        />
      </div>
      <div class="form-group-login">
        <label for="password" class="form-label">Mot de passe</label>
        <div class="password-wrapper">
          <input
              :type="showPassword ? 'text' : 'password'"
              id="password"
              class="form-control"
              v-model="password"
              required
          />
          <span
              class="toggle-password"
              @click="togglePassword"
              aria-label="Afficher/masquer le mot de passe"
          >
            🧿
          </span>
        </div>
      </div>

      <button type="submit" class="btn btn-primary btn-block">
        Se connecter
      </button>
    </form>

    <div class="link-container">
      <a @click="switchToForgotPassword" class="forgot-password-link" role="button">
        Mot de passe oublié ?
      </a>
      <div class="separator"></div>
      <a @click="switchToRegister" class="register-link" role="button">
        Créer un compte
      </a>
    </div>

    <div v-if="loginError" class="alert alert-danger mt-3">
      {{ loginError }}
    </div>
  </div>
</template>

<script>
import axiosInstance from "../axiosConfig";
import { store } from "../store";

export default {
  setup() {
    return { store };
  },
  data() {
    return {
      username: "",
      password: "",
      loginError: "",
      isAuthenticated: false,
      showPassword: false,
    };
  },
  methods: {
    async login() {
      try {
        const formData = new FormData();
        formData.append("username", this.username);
        formData.append("password", this.password);

        const { data } = await axiosInstance.post(`${this.$apiUrl}/token`, formData);
        const { access_token, name, role, email } = data;
        if (access_token) {
          this.isAuthenticated = true;
          store.userInfo.token = access_token;
          store.userInfo.userName  = name;
          store.userInfo.userRole  = role;
          store.userInfo.userEmail = email;
          this.$emit("login-success", access_token, name, role, email);
          store.isLoggedIn = true;
          const urlParams = new URLSearchParams(window.location.search);

          const redirectUrl = urlParams.get('redirect');

          // Vérifier si le paramètre `redirect` existe et rediriger l'utilisateur
          if (redirectUrl) {
            this.$router.push(redirectUrl);
          } else {
            // Rediriger vers une page par défaut si aucun `redirect` n'est spécifié
            this.$router.push('/home');
          }
        }
      } catch (error) {
        this.loginError = "Échec de la connexion. Veuillez vérifier vos identifiants.";
        store.pucksMessage = "🚫 Vous n'avez pas dit le mot magique 🚫";
        this.$emit("login-error");
      }
    },
    switchToRegister() {
      this.$router.push('/register');
    },
    switchToForgotPassword() {
      this.$router.push('/forgot-password');
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
  },
  mounted() {
    const token = store.userInfo.token;
    console.log("Token from store:", token);
    if (token) {
      this.isAuthenticated = true;
      const name = store.userInfo.userName;
      const role = store.userInfo.userRole;
      const email = store.userInfo.userEmail
      this.$emit("login-success", token, name, role, email);
      this.$router.push('/home'); // Redirection si le token existe déjà
    } else {
      this.isAuthenticated = false;
    }
    store.pucksMessage = "Qui es-tu ?";
  },
};
</script>

<style scoped>
.login-container {
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 10px;
  padding: 30px;
  width: fit-content;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: #bbbbbb;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
  text-align: center;
}

.form-group-login {
  margin-bottom: 1rem;
}

.form-label {
  font-weight: bold;
  font-size: 0.9rem;
  color: #cccccc;
}

.form-control {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #444;
  border-radius: 5px;
  height: 2.5rem;
  padding-left: 10px;
  color: #333;
}

.password-wrapper {
  position: relative;
}

.toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: #666;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  border-radius: 5px;
  width: 100%;
  height: 2.5rem;
  font-weight: bold;
  color: #fff;
  margin-top: 15px;
}

.link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  width: 100%;
}

.separator {
  height: 10px; /* Ajout d'un espace vertical entre les liens */
}

.register-link,
.forgot-password-link {
  color: #00aaff;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
}

.register-link:hover,
.forgot-password-link:hover {
  text-decoration: none;
  color: #0056b3;
}

.alert-danger {
  background-color: rgba(220, 53, 69, 0.7);
  color: #ffffff;
  border-radius: 5px;
  padding: 0.5rem;
  font-size: 0.9rem;
}
</style>
