import { reactive, watch } from 'vue';

// Fonction pour récupérer les données depuis localStorage
const getInitialStore = () => {
  const storedData = localStorage.getItem('appStore');
  return storedData ? JSON.parse(storedData) : {
    isLoggedIn: false,
    apiUrl: 'store.apiUrl not set',
    errorMessage: {
      visible: false,
      message: ''
    },
    pucksMessage: "Bonjour",
    userInfo: {
      token: '',
      userName: '',
      userRole: '',
      userEmail: ''
    },
    fieldMap: {
      "MEP Name": "mep_name",
      "MEP nationalPoliticalGroup": "national_political_group",
      "MEP politicalGroup": "political_group",
      "Title": "title",
      "Place": "place",
      "Date": "date",
      "Meeting With": "meeting_with",
      "Meeting Related to Procedure": "meeting_related_to_procedure",
    },
    showApp: '',
  };
};

export const store = reactive(getInitialStore());

// Fonction pour sauvegarder les données dans localStorage
const saveStoreToLocalStorage = () => {
  localStorage.setItem('appStore', JSON.stringify(store));
};

// Observer les changements dans le store et sauvegarder dans localStorage
watch(store, saveStoreToLocalStorage, { deep: true });

store.wait = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

store.generatePucksLoading = async () => {
  store.pucksMessage = "Du travail, encore du travail";
  await store.wait(1000);
  store.pucksMessage += ".";
  await store.wait(1000);
  store.pucksMessage += ".";
  await store.wait(1000);
  store.pucksMessage += ".";
  await store.wait(1000);
  store.pucksMessage += ".";
  await store.wait(1000);
  store.pucksMessage += ".";
};

store.updatePucksSpeech = async (message) => {
  store.pucksMessage = message;
};
