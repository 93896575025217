<template>
  <div class="activation-container d-flex flex-column justify-content-center align-items-center">
    <h2 class="title">Activation de compte</h2>
    <p v-if="activationMessage" class="status-message alert-success">{{ activationMessage }}</p>
    <p v-if="errorMessage" class="status-message alert-danger">{{ errorMessage }}</p>
    <!-- Bouton de retour vers Home -->
    <button class="btn btn-primary mt-4" @click="goToHome">Retour à l'accueil</button>
  </div>
</template>wikipedia

<script>
import axiosInstance from "../axiosConfig";

export default {
  name: "AccountActivation",
  data() {
    return {
      activationMessage: "",
      errorMessage: ""
    };
  },
  created() {
    const token = this.$route.query.token; // Récupérer le token depuis l'URL
    if (token) {
      this.activateAccount(token);
    } else {
      this.errorMessage = "Token invalide ou manquant.";
    }
  },
  methods: {
    async activateAccount(token) {
      try {
        const response = await axiosInstance.get(`${this.$apiUrl}/activate`, { params: { token } });
        this.activationMessage = response.data.message;
      } catch (error) {
        this.errorMessage =
            error.response?.data?.detail || "Erreur lors de l'activation du compte.";
      }
    },
    goToHome() {
      this.$router.push('/home'); // Redirige vers la page Home
    }
  }
};
</script>

<style scoped>
.activation-container {
  background-color: rgba(0, 0, 0, 0.8); /* Fond sombre semi-transparent */
  border-radius: 10px;
  padding: 30px;
  width: 100%;
  max-width: 400px;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  margin: auto;
  margin-top: 50px; /* Éviter le chevauchement avec une navbar */
}

.title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 1rem;
}

.status-message {
  font-size: 1rem;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
}

.alert-success {
  background-color: rgba(40, 167, 69, 0.7); /* Couleur de succès */
  color: #ffffff;
}

.alert-danger {
  background-color: rgba(220, 53, 69, 0.7); /* Couleur d'erreur */
  color: #ffffff;
}

.btn {
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #007bff;
  color: #ffffff;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.mt-4 {
  margin-top: 1.5rem;
}
</style>
