<template>
  <div class="forgot-password-container d-flex flex-column justify-content-center align-items-center">
    <form @submit.prevent="requestPasswordReset" class="forgot-password-form">
      <div class="form-group-register">
        <label for="email" class="form-label">Email</label>
        <input
            type="email"
            id="email"
            class="form-control"
            v-model="email"
            placeholder="Entrez votre email"
            required
        />
      </div>
      <div class="mt-auto w-100 text-center">
        <button type="submit" class="btn btn-primary">
          <span v-if="isLoading" class="spinner"></span>
          <span v-else>Envoyer</span>
        </button>
      </div>
    </form>

    <div v-if="statusMessage" :class="statusClass" class="status-message mt-3">
      {{ statusMessage }}
    </div>
  </div>
</template>

<script>
import axiosInstance from "../axiosConfig";
import { store } from "@/store";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: "",
      statusMessage: "",
      statusClass: "",
      isLoading: false, // Indicateur de chargement
    };
  },
  methods: {
    async requestPasswordReset() {
      this.isLoading = true;
      try {
        // Préparer les données pour un format URL-encoded
        const formData = new URLSearchParams();
        formData.append("email", this.email);

        // Envoyer la requête avec les en-têtes appropriés
        const response = await axiosInstance.post(`${this.$apiUrl}/forgot-password`, formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/json",
          },
        });

        this.statusMessage = response.data.message;
        this.statusClass = "alert alert-success";
      } catch (error) {
        this.statusMessage =
            error.response?.data?.detail || "Erreur lors de la demande de réinitialisation.";
        this.statusClass = "alert alert-danger";
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    const today = new Date(); // Obtenir la date actuelle
    const formattedDate = new Intl.DateTimeFormat("fr-FR", {
      weekday: "long", // Jour de la semaine
      day: "numeric", // Numéro du jour
      month: "long", // Mois complet
      year: "numeric", // Année
    })
        .format(today)
        .normalize("NFD") // Décompose les caractères accentués
        .replace(/[\u0300-\u036f]/g, ""); // Supprime les diacritiques

    store.pucksMessage =
        "Nous sommes le " + formattedDate + " et on se permet encore de perdre son mot de passe... 🤦‍♀️";
  }
};
</script>

<style scoped>
.forgot-password-container {
  background-color: rgba(0, 0, 0, 0.8); /* Fond sombre semi-transparent */
  border-radius: 10px;
  padding: 30px;
  width: 100%;
  max-width: 400px;
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  margin: auto;
  margin-top: 50px;
}

.forgot-password-form .form-group-register {
  margin-bottom: 15px;
}

.form-label {
  font-weight: bold;
  font-size: 0.9rem;
  color: #cccccc;
  margin-bottom: 0.5rem;
  display: block;
}

.form-control {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #444;
  border-radius: 5px;
  height: 2.5rem;
  padding-left: 10px;
  color: #333;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  border-radius: 5px;
  height: 2.5rem;
  width: 100%;
  font-weight: bold;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.btn-primary:hover {
  background-color: #0056b3;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid #ffffff;
  border-top: 3px solid #007bff;
  border-radius: 50%;
  animation: spin 0.7s linear infinite;
  display: inline-block;
  vertical-align: middle;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
