//router.js
import { createRouter, createWebHistory } from 'vue-router';
import { store } from './store';
import log from 'loglevel';
import FileUpload from './components/FileUpload.vue';
import LoginMod from './components/Login_mod.vue';
import Admin from './components/Admin_mod.vue';
import Democracy from './components/Democracy_mod.vue';
import MultiTools from './components/MultiTools.vue';
import RoleBasedModule from './components/RoleBasedModule.vue';
import GpxAnalyzer from './components/GpxAnalyzer.vue';
import UserRegister from './components/UserRegister.vue';
import ForgotPassword from './components/ForgotPassword.vue';
import ResetPassword from './components/ResetPassword.vue';
import AccountActivation from './components/AccountActivation.vue';
import GetFile from './components/GetFile.vue';

// Configurer le niveau de log
log.setLevel('debug');

// Définition des routes
const routes = [
  { path: '/', component: LoginMod },
  { path: '/register', component: UserRegister },
  { path: '/forgot-password', component: ForgotPassword },
  { path: '/activate', component: AccountActivation },
  { path: '/reset-password', component: ResetPassword },
  {
    path: '/home',
    component: RoleBasedModule,
    meta: { requiresAuth: true },
  },
  {
    path: '/home/file-upload',
    component: FileUpload,
    meta: { requiresAuth: true, allowedRoles: ['admin', 'gold_digger'] },
  },
  {
    path: '/home/admin',
    component: Admin,
    meta: { requiresAuth: true, allowedRoles: ['admin'] },
  },
  {
    path: '/home/democracy',
    component: Democracy,
    meta: { requiresAuth: true, allowedRoles: ['admin', 'meps'] },
  },
  {
    path: '/home/multitools',
    component: MultiTools,
    meta: { requiresAuth: true, allowedRoles: ['admin', 'tools', 'bundle'] },
  },
  {
    path: '/home/gpx-analyzer',
    component: GpxAnalyzer,
    meta: { requiresAuth: true, allowedRoles: ['admin', 'gpx', 'bundle'] },
  },
  {
    path: '/file',
    component: GetFile,
    meta: { requiresAuth: true}
  }
];

// Création du router Vue
const router = createRouter({
  history: createWebHistory(),
  routes, // Lier les routes ici
});

router.beforeEach((to, from, next) => {
  log.debug('Navigation attempt:', to.path);
  log.debug('store.userInfo:',store.userInfo)
  // Vérifier si l'utilisateur est authentifié et tente d'accéder à la route "/"
  if (to.path === '/' && store.isLoggedIn && store.userInfo.token) {
    log.info('User is authenticated, redirecting to home');
    return next('/home');
  }

  // Vérifier si la route nécessite une authentification
  if (to.matched.some(record => record.meta.requiresAuth)) {
    log.debug('Authentication required for route:', to.path);
    log.debug('User is logged in:', store.isLoggedIn);
    log.debug('User token:', store.userInfo.token);

    // Si l'utilisateur n'est pas authentifié, rediriger vers la page de login
    if (!store.isLoggedIn || !store.userInfo.token) {
      log.info('User not authenticated, redirecting to login');
      return next({ path: '/', query: { redirect: to.fullPath } });
    }

    // Vérification des rôles autorisés
    let allowedRoles = [];
    to.matched.forEach(record => {
      if (record.meta.allowedRoles) {
        log.debug('Allowed roles for route:', record.meta.allowedRoles);
        allowedRoles = record.meta.allowedRoles;
      }
    });

    const userRole = store.userInfo?.userRole;
    log.debug('Allowed roles:', allowedRoles);
    log.debug('User role:', userRole);

    if (allowedRoles.length > 0 && !allowedRoles.includes(userRole)) {
      log.warn('User role not allowed, redirecting to home');
      store.errorMessage = {
        visible: true,
        message: "Vous n'êtes pas autorisé à accéder à cette page.",
      };
      return next('/home');
    }

    log.info('Access granted to route:', to.path);
    next(); // Continuer vers la route prévue
  } else {
    // Aucune authentification requise pour cette route
    log.debug('No authentication required for route:', to.path);
    next(); // Continuer vers la route prévue
  }
});


export default router;
