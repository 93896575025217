<template>
  <div class="get-gpx-report-container d-flex flex-column justify-content-center align-items-center">
    <h2 class="title">Téléchargement du fichier</h2>
    <p v-if="statusMessage" :class="statusClass" class="status-message">{{ statusMessage }}</p>
    <button class="btn btn-primary mt-4" @click="GetFile" :disabled="isLoading">
      <span v-if="isLoading" class="spinner"></span>
      <span v-else>Télécharger le fichier</span>
    </button>
    <button class="btn btn-secondary mt-3" @click="goToHome">Retour à l'accueil</button>
  </div>
</template>

<script>
import axiosInstance from "../axiosConfig";
import { store } from "@/store";

export default {
  name: "GetFile",
  data() {
    return {
      statusMessage: "",
      statusClass: "",
      isLoading: false,
    };
  },
  created() {
    console.log("[GetFile] Component mounted.");
    console.log("[GetFile] Current route:", this.$route.fullPath);
    const file_id = this.$route.query.file_id;
    console.log("[GetFile] Extracted file_id from URL:", file_id);
    if (!file_id) {
      this.statusMessage = "Paramètre file_id manquant dans l'URL.";
      this.statusClass = "alert alert-danger";
    }
  },
  methods: {
    async GetFile() {
      console.log("[GetFile] Starting file download...");
      this.isLoading = true;
      store.pucksMessage = "Telechargement en cours...";

      const token = store.userInfo?.token;
      console.log("[GetFile] Retrieved token:", token);
      if (!token) {
        this.statusMessage = "Aucun token trouvé. Veuillez vous reconnecter.";
        this.statusClass = "alert alert-danger";
        this.isLoading = false;
        return;
      }

      const file_id = this.$route.query.file_id;
      console.log("[GetFile] Using file_id:", file_id);

      const file_type = this.$route.query.file_type;
      console.log("[GetFile] Using file_type:", file_type);

      if (!file_id) {
        this.statusMessage = "Paramètre file_id manquant.";
        this.statusClass = "alert alert-danger";
        this.isLoading = false;
        return;
      }

      if (!file_type){
        this.statusMessage = "Paramètre file_type manquant.";
        this.statusClass = "alert alert-danger";
        this.isLoading = false;
        return;
      }

      try {
        console.log("[GetFile] Sending request to API...");
        const response = await axiosInstance.get(`${this.$apiUrl}/file`, {
          params: { file_id: file_id, file_type: file_type },
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob',
        });

        console.log("[GetFile] API response received, processing file...");

        // Log all headers for debugging
        console.log("[GetFile] Response:", response)
        console.log("[GetFile] Response headers raw:", response.headers);
        let filename = 'download';
        const contentDisposition = response.headers['content-disposition'];
        if (contentDisposition) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(contentDisposition);
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
          }
        } else {
          filename = `file_${file_id}.${file_type}`;
          const fileTypeMap = {
            'gpx_report': 'zip',
            'bad_guys_file': 'txt',
            'converted_docx': 'docx',
            // Add more file types as needed
          };
          filename = `file_${file_id}.${fileTypeMap[file_type] || file_type}`;
        }

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        // Clean up
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);

        console.log("[GetFile] File downloaded successfully.");
        this.statusMessage = "Fichier téléchargé avec succès";
        this.statusClass = "alert alert-success";
      } catch (error) {
        console.error("[GetFile] Error fetching file:", error);
        this.statusMessage = error.response?.data?.detail || "Erreur lors du téléchargement du fichier.";
        this.statusClass = "alert alert-danger";
      } finally {
        this.isLoading = false;
        store.pucksMessage = "Telechargement termine.";
        console.log("[GetFile] Download process finished.");
      }
    },
    goToHome() {
      console.log("[GetFile] Navigating back to home page");
      this.$router.push('/');
    }
  }
};
</script>

<style scoped>
.get-gpx-report-container {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  padding: 30px;
  width: 100%;
  max-width: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
  margin-top: 50px;
}

.title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 1rem;
}

.status-message {
  font-size: 1rem;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
  margin-top: 15px;
}

.alert-success {
  background-color: rgba(40, 167, 69, 0.7);
  color: #ffffff;
}

.alert-danger {
  background-color: rgba(220, 53, 69, 0.7);
  color: #ffffff;
}

.btn {
  font-size: 1rem;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #007bff;
  color: #ffffff;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  color: #ffffff;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid #ffffff;
  border-top: 3px solid #007bff;
  border-radius: 50%;
  animation: spin 0.7s linear infinite;
  display: inline-block;
  vertical-align: middle;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>