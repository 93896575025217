<template>
  <div class="multi-tools">
    <div>
      <div class="image-button-container">
        <div
            v-if="showApp !== 'file-upload-bad-guys'"
            class="image-button"
            @click="goToFileUploadBadGuys"
            :style="{ backgroundImage: 'url(' + catchBadGuys + ')' }"
        >
          <span class="button-text">Catch 'em all!<br/>🦸🏻 ⛓️ 🦹🏻</span>
        </div>
        <div class="sub-text" @click="goToFileUploadBadGuys">
          Détection d'IP malveillantes <br/>
          depuis un access.log Traefik
        </div>
        <FileUploaderGen
            v-if="store.isLoggedIn && showApp === 'file-upload-bad-guys'"
            :route="'/queue_job?job_type=bad_guys_list'"
            :fileName="''"
            @upload-complete="resetView"
        />
      </div>
    </div>
    <div>
      <div class="image-button-container">
        <div
            v-if="showApp !== 'file-upload-pdf2word'"
            class="image-button"
            @click="goToFileUploaderPdf2Word"
            :style="{ backgroundImage: 'url(' + pdfToWord + ')' }"
        >
          <span class="button-text">PDF ⏩ Word </span>
        </div>
        <div class="sub-text" @click="goToFileUploaderPdf2Word">
          Conversion PDF ⏩ Word (.docx)
        </div>
        <FileUploaderGen
            v-if="store.isLoggedIn && showApp === 'file-upload-pdf2word'"
            :route="'/queue_job?job_type=pdf'"
            :fileName="''"
            @upload-complete="resetView"
        />
      </div>
    </div>
  </div>
  <!-- Pop-up stylisée -->
  <div v-if="showPopup" class="custom-popup">
    <div class="popup-content">
      <p>
        {{popupMessage}}, <br/> l'adresse
        <i>{{ store.userInfo.userEmail }}</i>
        va se faire statisfacter ✅ 📨
      </p>
      <button @click="closePopup" class="btn-close-marcel">
        OK
      </button>

    </div>
  </div>
</template>

<script>
import FileUploaderGen from "./FileUploaderGen.vue";
import {store} from "@/store";

export default {
  setup() {
    return {store};
  },
  components: {
    FileUploaderGen,
  },
  data() {
    return {
      catchBadGuys: require("../../images/bad_guys.webp"),
      pdfToWord: require("../../images/pdf_to_word.webp"),
      showApp: "",
      showPopup: false, // état pour la pop-up
      popupMessage: "", // message conditionnel pour la pop-up
    };
  },
  methods: {
    goToFileUploadBadGuys() {
      this.showApp = "file-upload-bad-guys"; // Met à jour l'état pour afficher le composant
    },
    goToFileUploaderPdf2Word() {
      this.showApp = "file-upload-pdf2word";
    },
    resetView() {
      // Conditionnelle pour le message de la pop-up
      if (this.showApp === "file-upload-bad-guys") {
        this.popupMessage = `Les suspects sont dans la file d'attente, ⛓️🕵️‍♂️`;
      } else if (this.showApp === "file-upload-pdf2word") {
        this.popupMessage = `Puck a lancé ses gros biscotos et ses pouvoirs magiques, 🪄✨✨✨ 📄➡️📝`;
      }

      this.showPopup = true; // Affiche la pop-up
      this.showApp = ""; // Réinitialise l'affichage initial après l'upload
    },
    closePopup() {
      this.showPopup = false; // Ferme la pop-up
    }
  },
  mounted() {
    console.log("UserRole:", store.userInfo.userRole);
    store.pucksMessage = "La, c'est pas tres range...";
  }
};
</script>

<style scoped>
</style>
